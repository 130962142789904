import React from 'react'
import Booking from '../components/Booking'

const Reservation = () => {
  return (
    <div>
        <Booking/>
    </div>
  )
}

export default Reservation