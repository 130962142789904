import React from 'react'
import Comments from '../components/Comments'

const CustomerComments = () => {
  return (
    <div>
        <Comments/>
    </div>
  )
}

export default CustomerComments